<template>
  <div>
    <Desktop v-if="isDesktop" />
    <Mobile v-else />
  </div>
</template>

<script>
import Desktop from "@/components/Login/Desktop.vue";
import Mobile from "@/components/Login/Mobile.vue";

export default {
  name: "Login",
  components: { Desktop, Mobile },
  data() {
    return {
      isDesktop: false,
    };
  },
  created() {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenSize); // Clean up the event listener
  },
  methods: {
    updateScreenSize() {
      this.isDesktop = window.matchMedia("(min-width: 768px)").matches; // Match your breakpoint
    },
  },
  computed: {},
};
</script>

<style scoped></style>
